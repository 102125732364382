.codeEditor {
  padding: 5px;
  margin-bottom: 1em;
  font-size: 0.9em;
  color: #fff;
  width: 100%;
  height: 15em;
  resize: none;
}

.line-numbers .line-numbers-rows {
  margin-top: 1.2em;
}

.btn {
  width: 100%;
}
